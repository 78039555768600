<template>
    <div class="loginBg">
        <div id="main">
            <div class="container">
                <div class="title wow animated fadeInDown">{{$t('login.reset')}}</div>
                <div class="form_box wow animated fadeIn">
                    <div class="inp_label">{{$t('common.userName')}}</div>
                    <input v-model="account" class="inp" type="text" data-index="username" :placeholder="$t('tip.usernameEmpty')" />

                    <div class="inp_label">
                        {{$t('common.verification')}}
                    </div>
                    <div class="inp_btn">
                        <input v-model="sms_code" class="inp" type="text" data-index="sms_code" :placeholder="$t('common.inputVerification')" />
                        
                        <div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
                        <div v-else class="g_btn btn" @click="handleSendCode">
                            <template v-if="firstSend">{{$t('common.sendVerification')}}</template>
                            <template v-else>{{$t('common.resend')}}</template>
                        </div>
                    </div>
                    
                     <div class="inp_label">{{$t('common.newPwd')}}</div>
                    <input v-model="password1" class="inp" type="password" data-index="password" :placeholder="$t('tip.commonVer')" />
                    
                    <div class="inp_label">{{$t('login.confirm')}}</div>
                    <input v-model="password2" class="inp" type="password" data-index="password" :placeholder="$t('tip.again')" />

                    <button @click="resetPwd" class="btn login">{{$t('login.confirmReset')}}</button>
                    
                </div>
                <div class="login_footer wow animated fadeInUp">
                    <p><span>System version {{version}} </span> @ 2022 LLKKZ.NET ALL RIGHTS RESERVED</p>
                </div>
            </div>
            <div class="icon">
                <img src="@/assets/images/official/login/icon.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
var timer;
import {Message} from 'element-ui'
import { WOW } from 'wowjs'
import { mapGetters } from 'vuex';
// api
import {updatePassword,sendEmail} from '@/api/user'
export default {
    data(){
        return{
            account:'',
            sms_code:'',
            password1:'',
            password2:'',
            // checked:false,
            codeSending: false,
            timeCount: 60,
            firstSend: true,
        }
    },
    computed:{
        ...mapGetters(['version'])
    },
    mounted(){
        this.$nextTick(()=>{
            this.wow();
        })
    },
    methods:{
        wow(){
            if (! (/msie [6|7|8|9]/i.test(navigator.userAgent))) {// 在非 IE 6-9 浏览器中执行逻辑
                var wow = new WOW({
                    boxClass:'wow',
                    animateClass:'animated',
                    offset:0,
                    mobile:true,
                    live:true
                });
                this.$nextTick(() => {
                    wow.init();
                });
            };
        },
        handleSendCode() {
            if(!this.account){
                Message({
                    message: this.$t('tip.usernameEmpty')+'！',
                    type: 'error',
                    duration: 3 * 1000
                });
            }else{
                if(!this.codeSending){
                    // 发送验证码
                    this.timeCount = 60;
                    this.codeSending = true;
                    let that = this;
                    this.firstSend = false;
                    this.firstSend2 = true;
                    clearInterval(timer);
                    timer = setInterval(() => {
                        if(this.timeCount >= 1) {
                            that.timeCount -= 1;
                        } else {
                            clearInterval(timer);
                            this.codeSending = false;
                        }
                    }, 1000);
                    // 发送验证码
                    sendEmail({
                        account:this.account,
                        email:'',
                        type:0,
                    })
                }
            }
        },
        // 重置密码
        resetPwd(){
            const reg = /[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/;
            const pwdReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()-_+=\[\]{};:'"\\|,<.>/?]).{8,}$/;
            if(!this.account){
                Message({
                    message: this.$t('tip.usernameEmpty')+'！',
                    type: 'error',
                    duration: 3 * 1000
                });
            }else if(!this.sms_code){
                Message({
                    message:this.$t('tip.codeEmpty')+'！',
                    type: 'error',
                    duration: 3 * 1000
                });
            }else if(!this.password1){
                Message({
                    message:this.$t('tip.pwdEmpty')+'！',
                    type: 'error',
                    duration: 3 * 1000
                });
            }else if(!pwdReg.test(this.password1)){
                Message({
                    message:this.$t('tip.pwdVer')+'！',
                    type: 'error',
                    duration: 3 * 1000
                });
            }else if(this.password1!=this.password2){
                Message({
                    message:this.$t('tip.pwd2')+'！',
                    type: 'error',
                    duration: 3 * 1000
                });
            }else{
                updatePassword({
                    account:this.account,
                    password:this.password2,
                    sms_code:this.sms_code,
                    type:0,
                }).then(res=>{
					if(res==1){
                        // 修改成功
                        this.$message({
                            message: this.$t('tip.editSuc'),
                            type: 'success',
                            center: true,
                            offset: 100,
                            customClass: 'shotMsg',
                        });
                        this.$router.push({
                            path:'/login'
                        })
                    }else{
                        // 修改失败
                        this.$message({
                            message: this.$t('tip.editErr'),
                            type: 'error',
                            center: true,
                            offset: 100,
                            customClass: 'shotMsg',
                        });
                    }
                })
            }
        },
    },
    beforeDestroy() {
        if(timer){
            clearInterval(timer)
            timer=''
        }
    }
}
</script>
<style scoped="scoped" lang="stylus">
	@import '~@/views/login/login.styl';
	@import '~@/assets/style/frames.styl';
</style>